// extracted by mini-css-extract-plugin
export var main = "styles-module--main--CSule";
export var footer = "styles-module--footer--Po+iQ";
export var outer = "styles-module--outer--YVxqE";
export var container = "styles-module--container--vPXaa";
export var inner = "styles-module--inner--mhxtj";
export var header = "styles-module--header--rjKw5";
export var contentWrapper = "styles-module--contentWrapper--tQk7K";
export var headerBar = "styles-module--headerBar--FyGK2";
export var sm = "styles-module--sm--PEXUg";
export var content = "styles-module--content--hmCMO";
export var listWelcome = "styles-module--listWelcome--GNmgq";
export var list = "styles-module--list--3I2+S";
export var detail = "styles-module--detail--uJ1Yp";