// extracted by mini-css-extract-plugin
export var icon = "styles-module--icon--ze5SR";
export var xxs = "styles-module--xxs--nLrtD";
export var xs = "styles-module--xs--KTd-I";
export var smS = "styles-module--smS--CsGLS";
export var sm = "styles-module--sm--towDP";
export var mdS = "styles-module--mdS--s5f9z";
export var md = "styles-module--md--jHOom";
export var lg = "styles-module--lg--0oJZd";
export var xlM = "styles-module--xlM--yOHas";
export var xl = "styles-module--xl--r4+Bg";
export var xxl = "styles-module--xxl--Lv0+y";
export var white = "styles-module--white--b9S12";
export var black = "styles-module--black--ZyldP";
export var blackLight = "styles-module--blackLight--CARCp";
export var greyDark = "styles-module--grey-dark--JQeR0";
export var grey = "styles-module--grey--PVpAA";
export var greyLight = "styles-module--greyLight--pZ9ND";
export var greyLighter = "styles-module--greyLighter---VNA4";
export var green = "styles-module--green---BSqw";
export var gold = "styles-module--gold--vhg0P";
export var red = "styles-module--red--9Dnh0";