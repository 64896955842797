// extracted by mini-css-extract-plugin
export var textIcon = "styles-module--textIcon---tNy6";
export var text = "styles-module--text--Ue8Ez";
export var placement = "styles-module--placement--k5Krc";
export var bottom = "styles-module--bottom--hLMwF";
export var size = "styles-module--size--jqZMF";
export var xs = "styles-module--xs--XbWg0";
export var sm = "styles-module--sm--jYQIm";
export var smS = "styles-module--smS--ZBR8W";
export var md = "styles-module--md--pJ-WB";
export var mdS = "styles-module--mdS--qmqcT";
export var xm = "styles-module--xm--u5MkM";
export var lg = "styles-module--lg--c0nF+";
export var color = "styles-module--color--whGoI";
export var black = "styles-module--black--WS2yi";
export var green = "styles-module--green--irHcJ";
export var gold = "styles-module--gold--X12lk";
export var grey = "styles-module--grey--c2-ey";
export var greyLight = "styles-module--greyLight--O4tnE";
export var greyDark = "styles-module--greyDark--zNctu";
export var greyDarker = "styles-module--greyDarker--jH-sQ";
export var white = "styles-module--white--ZvwM-";
export var red = "styles-module--red--kcVhi";
export var spacing = "styles-module--spacing--jTU55";
export var xxxTight = "styles-module--xxxTight--eOMe-";
export var hasIcon = "styles-module--hasIcon--20WPD";
export var right = "styles-module--right--tLVcf";
export var left = "styles-module--left--w+SqA";
export var xxTight = "styles-module--xxTight--aW7lv";
export var xTight = "styles-module--xTight--UWazG";
export var tight = "styles-module--tight--rPLa1";
export var base = "styles-module--base--T7FKw";
export var loose = "styles-module--loose--35J3J";
export var weight = "styles-module--weight--zRGnE";
export var light = "styles-module--light--amu-6";
export var normal = "styles-module--normal--WYNGd";
export var medium = "styles-module--medium--slBhN";
export var semibold = "styles-module--semibold--DPg8q";
export var bold = "styles-module--bold--aXtc1";
export var underline = "styles-module--underline--PoOXC";